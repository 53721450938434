.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.recruit-wrapper {
  padding-top: 90px;
}
.recruit-wrapper .banner-container {
  position: relative;
  box-sizing: border-box;
  height: 35.41666666666667vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16276374421256264.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #00092a;
}
.recruit-wrapper .banner-container .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.recruit-wrapper .banner-container .title .top {
  line-height: 1.4;
  font-size: 3.125vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #fff;
}
.recruit-wrapper .banner-container .title .bottom {
  line-height: 1.4;
  font-size: 3.125vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.recruit-wrapper .about-container {
  position: relative;
  box-sizing: border-box;
  height: 23.958333333333336vw;
  padding-top: 4.739583333333333vw;
  background-color: #fff;
}
.recruit-wrapper .about-container::before,
.recruit-wrapper .about-container::after {
  content: "";
  position: absolute;
  width: 1.7187500000000002vw;
  height: 1.6145833333333335vw;
  background-size: cover;
  background-repeat: no-repeat;
}
.recruit-wrapper .about-container::before {
  content: "";
  top: 8.75vw;
  left: 33.229166666666664vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274884476575669.png);
}
.recruit-wrapper .about-container::after {
  content: "";
  bottom: 6.458333333333334vw;
  right: 34.53125vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274884532307948.png);
}
.recruit-wrapper .about-container > .title {
  margin-bottom: 2.34375vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.recruit-wrapper .about-container .desc {
  margin: 0;
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.recruit-wrapper .video-container {
  box-sizing: border-box;
  height: 55.93749999999999vw;
  padding-top: 2.083333333333333vw;
}
.recruit-wrapper .video-container > .title {
  margin-bottom: 4.0625vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.recruit-wrapper .video-container .video-box {
  position: relative;
  width: 75vw;
  height: 42.1875vw;
  margin: 0 auto;
  border-radius: 0.8333333333333334vw;
  overflow: hidden;
}
.recruit-wrapper .video-container .video-box .video {
  width: 100%;
  height: 100%;
}
.recruit-wrapper .video-container .video-box .poster {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.recruit-wrapper .video-container .video-box .poster .main {
  width: 100%;
  height: 100%;
}
.recruit-wrapper .video-container .video-box .poster .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4.791666666666667vw;
  height: 4.791666666666667vw;
  border-radius: 50%;
}
.recruit-wrapper .worth-container {
  box-sizing: border-box;
  height: 22.604166666666668vw;
  padding-top: 1.7187500000000002vw;
}
.recruit-wrapper .worth-container > .title {
  margin-bottom: 4.0625vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.recruit-wrapper .worth-container .items {
  display: flex;
  align-items: center;
  width: 75vw;
  margin: 0 auto;
}
.recruit-wrapper .worth-container .items .worth-item {
  margin-right: 5.208333333333334vw;
}
.recruit-wrapper .worth-container .items .worth-item:last-child {
  margin-right: 0;
}
.recruit-wrapper .worth-container .items .worth-item .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25vw;
  height: 6.25vw;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.06);
}
.recruit-wrapper .worth-container .items .worth-item .icon-box .icon {
  width: 3.75vw;
  height: 3.75vw;
}
.recruit-wrapper .worth-container .items .worth-item .text {
  text-align: center;
  margin-top: 0.625vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.recruit-wrapper .office-container {
  box-sizing: border-box;
  height: 53.333333333333336vw;
  padding-top: 4.84375vw;
  background-color: #f8f8f8;
}
.recruit-wrapper .office-container > .title {
  margin-bottom: 3.4375000000000004vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.recruit-wrapper .office-container .desc {
  width: 67.70833333333334vw;
  margin: 0 auto 1.82291667vw;
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.recruit-wrapper .office-container .swiper {
  position: relative;
  width: 67.70833333333334vw;
  border-radius: 0.8333333333333334vw;
  overflow: hidden;
  margin: 0 auto;
}
.recruit-wrapper .office-container .swiper .swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.2916666666666665vw;
  height: 2.2916666666666665vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.recruit-wrapper .office-container .swiper .swiper-arrow.prev {
  left: -2.8125vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274920200113393.png);
}
.recruit-wrapper .office-container .swiper .swiper-arrow.next {
  right: -2.8125vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274920199899246.png);
}
.recruit-wrapper .office-container .swiper .card-item {
  width: 67.70833333333334vw;
  height: 30.729166666666668vw;
  border-radius: 0.8333333333333334vw;
}
.recruit-wrapper .office-container .swiper .card-item .main {
  width: 100%;
}
.recruit-wrapper .atmosphere-container {
  box-sizing: border-box;
  height: 38.697916666666664vw;
  padding-top: 4.84375vw;
  background-color: #fff;
}
.recruit-wrapper .atmosphere-container > .title {
  margin-bottom: 3.125vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.recruit-wrapper .atmosphere-container .desc {
  width: 67.70833333333334vw;
  margin: 0 auto 3.125vw;
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.recruit-wrapper .atmosphere-container .swiper {
  position: relative;
  width: 67.70833333333334vw;
  margin: 0 auto;
}
.recruit-wrapper .atmosphere-container .swiper .swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.2916666666666665vw;
  height: 2.2916666666666665vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.recruit-wrapper .atmosphere-container .swiper .swiper-arrow.prev {
  left: -2.8125vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274920200113393.png);
}
.recruit-wrapper .atmosphere-container .swiper .swiper-arrow.next {
  right: -2.8125vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274920199899246.png);
}
.recruit-wrapper .atmosphere-container .swiper .card-item {
  width: 21.145833333333332vw;
  height: 14.583333333333334vw;
}
.recruit-wrapper .atmosphere-container .swiper .card-item .main {
  width: 100%;
}
.recruit-wrapper .benefits-container {
  box-sizing: border-box;
  height: 28.281250000000004vw;
  padding-top: 1.7187500000000002vw;
}
.recruit-wrapper .benefits-container > .title {
  margin-bottom: 3.125vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.recruit-wrapper .benefits-container .desc {
  margin-bottom: 2.604166666666667vw;
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.recruit-wrapper .benefits-container .items {
  width: 31.25vw;
  margin: 0 auto;
}
.recruit-wrapper .benefits-container .items .item {
  display: flex;
  align-items: center;
  margin-bottom: 1.7187500000000002vw;
}
.recruit-wrapper .benefits-container .items .item .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.46875vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274939517459632.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.recruit-wrapper .benefits-container .items .item .text {
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.recruit-wrapper .join-container {
  box-sizing: border-box;
  height: 16.666666666666664vw;
  padding-top: 5.989583333333334vw;
  background-color: #f8f8f8;
  background-image: url(https://mktv-in-cdn.mockuai.com/16274946481825353.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.recruit-wrapper .join-container .title {
  margin-bottom: 0.46875vw;
  text-align: center;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.recruit-wrapper .join-container .email {
  display: block;
  text-align: center;
  text-decoration: none;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff6600;
}
.recruit-wrapper .join-container .email:hover {
  text-decoration: underline;
}
